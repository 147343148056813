
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AuthService from '../services/auth-service';
import { State, Action, Getter } from 'vuex-class';
import MasterLayout from '@/components/MasterLayout.vue';
import { Address, Email, AddressChallengeResponse } from '@/store/models/AddressModel';
import { ConstValues } from '@/ConstValues';
import { AddressService } from '@/services/address-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';

@Component({
  components: { MasterLayout }
})
export default class UserRegisterComponent extends Vue {
  $refs!: {
    registrationForm: HTMLFormElement;
  };
  @Action('login', { namespace: 'profile' })
  login: any;
  firstName: string = '';
  lastName: string = '';
  password: string = '';
  confirmPassword: string = '';
  email: string = '';
  userName: string = '';
  errors: string[] = [];
  emailRules: any[] = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ];
  valid: boolean = false;
  isCreatingAccount: boolean = false;
  addressToEdit: Address = new Address();
  didCreateAccount: boolean = false;
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  get passwordConfirmationRule() {
    return this.password === this.confirmPassword || 'Password must match';
  }
  goToLogin(): void {
    this.$router.push('/login');
  }
  async validateAddress(): Promise<boolean> {
    let model = ConversionHelper.convertAddressToAddressModel(
      this.addressToEdit,
      true,
      true,
      true
    );
    try {
      const response = await AddressService.VerifyAddress(model);
      let addressResponse = new AddressChallengeResponse(response.data);
      if (addressResponse.HasValidationErrors) {
        SetSnackBar(`Address validation failed: ${addressResponse.ValidationErrors[0]}`);
        return false;
      }
      return true;
    } catch (err) {
      SetSnackBar('Address validation failed');
      return false;
    }
  }

  async saveUser() {
    this.isCreatingAccount = true;
    try {
      // First, validate the address
      const isAddressValid = await this.validateAddress();
      if (!isAddressValid) {
        // If address is not valid, stop the process
        this.isCreatingAccount = false;
        return;
      }

      // If address is valid, proceed with user creation
      const formData: any = new FormData();
      formData.set('userName', this.userName);
      formData.set('FirstName', this.firstName);
      formData.set('LastName', this.lastName);
      formData.set('Password', this.password);
      formData.set('CompanyName', this.addressToEdit.CompanyName);
      formData.set('Email', this.userName);

      await AuthService.createUser(formData);

      this.$gtag.event('new_user_sign_up', {
        event_category: 'Custom',
        event_label: 'New Signup',
        value: 500
      });

      await this.AddAddress();
      this.didCreateAccount = true;
      window.scrollTo(0, 0);
    } catch (error) {
      SetSnackBar('Something went wrong while signing you up');
    } finally {
      this.isCreatingAccount = false;
    }
  }
  goToAmazonTransparency() {
    window.location.replace('https://transparency.just1label.com');
  }

  async GetStates() {
    const response = await AddressService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await AddressService.GetCountries();
    this.countries = response.data;
  }

  async AddAddress() {
    try {
      this.addressToEdit.Email = new Email({ EmailAddress: this.userName });
      let model = ConversionHelper.convertAddressToAddressModel(
        this.addressToEdit,
        true,
        true,
        true
      );
      let response = await AddressService.AddNewBillingAddress(model);
      let addressResponse = new AddressChallengeResponse(response.data);
      if (addressResponse.HasValidationErrors) {
        SetSnackBar(
          `Failed to add new address, reason: ${addressResponse.ValidationErrors[0]}`
        );
      }
    } catch (err) {
      SetSnackBar('There was an error saving your billing address');
    }
  }

  get isRegistrationValid() {
    if (this.$refs.registrationForm) {
      this.valid = this.$refs.registrationForm.validate();
    }

    return this.valid && this.userName && this.addressToEdit.CompanyName && this.password;
  }
  get ConstValues() {
    return ConstValues;
  }

  mounted() {
    this.GetStates();
    this.GetCountries();
    // DisableFormAutofill('registrationForm');
  }
}
